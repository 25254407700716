<template>
  <v-card>
    <DataTablePagination
      :page="page"
      :total-items="totalItems"
      :headers="headers"
      :items="violationCategories"
      title="Pelanggaran Siswa"
      subtitle="Halaman untuk menambahkan pelanggaran siswa"
      add-btn="Tambah Data"
      :total-pages.sync="totalPages"
      @add-item="showFormAdd"
    >
    </DataTablePagination>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      @close="modalDialog = false"
    >
      <template v-slot:header>
        Pelanggaran Siswa
      </template>
      <template v-slot:body>
        <v-row>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            class="pb-0 pt-1"
          >
            <div>
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Tanggal"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click="autofillDate"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            class="pb-0 pt-1"
          >
            <div>
              <v-text-field
                label="Nama Siswa"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            class="pb-0 pt-1"
          >
            <div>
              <v-autocomplete
                label="Pelanggaran"
                dense
                outlined
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            class="pb-0 pt-1"
          >
            <div>
              <v-text-field
                label="Poin"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="pb-0 pt-1"
          >
            <div>
              <v-textarea
                label="Sebab"
                dense
                outlined
                height="100"
              >
              </v-textarea>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="pb-0 pt-1"
          >
            <div>
              <v-autocomplete
                label="Sanksi"
                dense
                outlined
              ></v-autocomplete>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="pb-0 pt-1"
          >
            <div>
              <v-textarea
                label="Penanganan"
                dense
                outlined
                height="100"
              >
              </v-textarea>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="pb-0 pt-1"
          >
            <div>
              <v-textarea
                label="Keterangan"
                dense
                outlined
                height="100"
              >
              </v-textarea>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          large
        >
          Tambah
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
        >
          Batal
        </v-btn>
      </template>
    </ModalDialog>
  </v-card>
</template>

<script>
import DataTablePagination from '../../components/DataTablePagination.vue'
import ModalDialog from '../../components/ModalDialog.vue'

export default {
  name: 'Violation',
  components: {
    DataTablePagination,
    ModalDialog,
  },
  data() {
    return {
      page: 1,
      totalItems: 10,
      modalDialog: false,
      totalPages: 0,
      width: '600',
      headers: [
        { text: 'No.', value: 'no' },
        { text: 'Tanggal', value: 'date' },
        { text: 'Nama Siswa', value: 'student' },
        { text: 'Pelanggaran', value: 'violation' },
        { text: 'Poin', value: 'point' },
        { text: 'Sebab', value: 'reason' },
        { text: 'Sanksi', value: 'penalty' },
        { text: 'Penanganan', value: 'handling' },
        { text: 'Keterangan', value: 'explaination' },
        { text: 'Action', value: 'actions' },
      ],
      violationCategories: [
        {
          no: '#',
          date: '24/12/2021',
          student: 'Ridho Mackinnon',
          violation: 'Berkelahi',
          point: 100,
          reason: 'Saling emosi',
          penalty: 'Hormat bendera',
          handling: 'Dinasehati',
          explaination: 'Tidak akan mengulangi lagi',
        },
        {
          no: '#',
          date: '24/12/2021',
          student: 'Ridho Mackinnon',
          violation: 'Menyontek',
          point: 20,
          reason: 'Tidak belajar',
          penalty: 'Bawa bunga dan potnya',
          handling: 'Dinasehati',
          explaination: 'Murid selalu mengerjakan tugas',
        },
        {
          no: '#',
          date: '24/12/2021',
          student: 'Ridho Mackinnon',
          violation: 'Tidak mengerjakan tugas',
          point: 50,
          reason: 'Malas belajar',
          penalty: 'Scorsing',
          handling: 'Dinasehati',
          explaination: 'Murid selalu mengerjakan tugas',
        },
      ],
    }
  },
  methods: {
    showFormAdd() {
      this.modalDialog = true
    },
    // autofillDate() {
    //   this.studentViolation.violation_date = new Date().toISOString().substr(0, 10)
    // },
  },
}
</script>

<style>
</style>
